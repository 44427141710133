<template>
    <div class="px-3">
        <div class="row justify-content-md-center py-3">
            <div class="col-md-6">
                <h3 class="h3">Update Password</h3>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-md-6">
                <form>
                    <div class="form-group">
                        <label for="newPassword">New Password</label>
                        <input
                            type="password"
                            class="form-control"
                            id="newPassword"
                            name="newPassword"
                            v-model="newPassword"
                            required
                            minlength="1"
                            maxlength="32"
                            ref="newPassword"
                        />
                    </div>
                    <div class="form-group">
                        <label for="newPasswordConfirmation"
                            >New Password Confirmation</label
                        >
                        <input
                            type="password"
                            class="form-control"
                            id="newPasswordConfirmation"
                            name="newPasswordConfirmation"
                            v-model="newPasswordConfirmation"
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="submit"
                            class="btn btn-primary mx-auto"
                            value="Submit"
                            v-bind:disabled="
                                !(
                                    newPasswordConfirmation &&
                                    newPassword == newPasswordConfirmation
                                )
                            "
                            v-on:click.stop.prevent="submit()"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { authService } from "../service/index";

export default {
    name: "UpdatePassword",
    data() {
        return {
            newPassword: null,
            newPasswordConfirmation: null
        };
    },
    methods: {
        submit() {
            const self = this;

            self.$store.commit("setShowLoading", true);

            const url = `/api/v1/account/password`;

            self.$http
                .put(url, {
                    new_password: self.newPassword
                })
                .then(function(response) {
                    if (response.status == 200) {
                        self.$router.push("/");

                        self.$notify({
                            group: "main",
                            type: "success",
                            text: "Updated password successfully!"
                        });
                    } else {
                        self.$notify({
                            group: "main",
                            type: "error",
                            text: error.response
                                ? error.response.data.message
                                : error
                        });
                    }
                })
                .catch(function(error) {
                    self.$notify({
                        group: "main",
                        type: "error",
                        text: error.response
                            ? error.response.data.message
                            : error
                    });
                })
                .finally(() => {
                    self.$store.commit("setShowLoading", false);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
